//navbar
.navbar{
  padding: 0;
  .navbar-brand{
    padding: 1vh 1vw;
  }
  .nav-item{
    &.dropdown{
      .dropdown-menu{
        top: 97%;
        right: auto;
        left: auto;
        border-radius: 0;
      }
      &:last-child{
        .dropdown-menu{
          right: 0;
        }
      }
      &:hover{
        .dropdown-menu{
          display: block;
        }
      }
    }
  }
  .form-inline{
    padding: 1vh 1vw;
  }
  .navbar-category{
    background: $primary;
  }
}

.breadcrumb{
  border-radius: 0;
}

//sections

.landing-section{
  .carousel{
    .carousel-item{
      .carousel-caption{
        right: auto;
        left: 5%;
        color: $primary;
        text-align: left;
      }
    }
  }
}

.product-section{
  .list-group{
    padding-bottom: 5vh;
  }
}

//general

.section-{
  &primary{
    padding: 2.5vh 0;
    color: $white;
    background: $primary;
  }
  &secondary{
    padding: 2.5vh 0;
    color: $white;
    background: $secondary;
  }
}


.intro{
  padding: 5vh 0;
}

.table{
  .table-min{
    width: 0;
  }
}

//category

.category-section{
  padding-bottom: 5vh;
  .category{
    margin: 1vh 0;
    border-radius: 0;
  }
}

//products

.products-section{
  padding-bottom: 5vh;
  .product{
    margin: 1vh 0;
    border-radius: 0;
  }
}

//footer
.footer{
  padding: 2vh 2vw;
  background: rgba($gray-600, .4);
  .list-group{
    .list-group-item{
      background: rgba(0, 0, 0, 0);
    }
  }
}
